const { REACT_APP_API, REACT_APP_PAYMENT_API, REACT_APP_PAYMENT_SKEY } = process.env;

export const SESSION_TOKEN = "crypto-session";
export const ACCESS_TOKEN = "crypto-token";

export const customFetch = ({
    api_uri,
    method = "GET",
    extraHeaders,
    body = null,
    includeHeaders = false,
    isFile = false
}) => new Promise(async (resolve, reject) => {
    // console.log((extraHeaders ?? {}));
    try {
        const accessToken = localStorage.getItem(ACCESS_TOKEN);
        const authHeader = accessToken ? { 'Authorization': `Bearer ${accessToken}` } : {};
        var headers = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': "*",
            ...extraHeaders
        };

        if (includeHeaders) {
            headers = { ...headers, ...authHeader, }
        }

        let options = {
            method,
            headers: headers
        };

        if (method !== 'GET' && body) options.body = JSON.stringify(body);

        const response = await fetch(`${REACT_APP_API}/${api_uri}`, options);

        // if (!result.ok) reject(new Error('Something went wrong'));
        var result = await response.json()
        if (response?.ok) {
            resolve(result);
        }
        // if (result?.message) reject(new Error(result?.message ?? "Something went wrong. Please check your input"));

        resolve(result);
    } catch (error) {
        reject(error);
    }
})

export const stripeFetch = ({ api_uri, method, body, extraHeaders }) => new Promise(async (resolve, reject) => {
    try {
        const response = await fetch(`${REACT_APP_PAYMENT_API}/${api_uri}`, {
            method,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                ...(extraHeaders ?? {}),
                'Authorization': "Bearer " + REACT_APP_PAYMENT_SKEY,
            },
            body: JSON.stringify(body),
        });

        // if (!result.ok) reject(new Error('Something went wrong'));

        var result = await response.json()

        // if (result?.message) reject(new Error(result?.message ?? "Something went wrong. Please check your input"));

        resolve(result);
    } catch (error) {
        reject(error);
    }
})



export const globalKeys = {
    LOGIN_TOKEN: 'login_token',
    USER_DATA: 'user_data'
}
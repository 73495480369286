import React, { useEffect, useRef, useState } from 'react';
import { Container, Row, Col, Image, Form, Button } from 'react-bootstrap';


export function Steps(props) {
    
    const isActiveStep = (step, currentstep) => {

        if (step === currentstep || step < currentstep) return 'active';

        return '';
    }

    return (
        <>
            <Col lg={4} className="step-menu d-none d-md-flex">
                <ul className="step-menu__list">
                    <li className={isActiveStep(1, props.step)}>
                        <h2>Create Account</h2>
                    </li>
                    <li className={isActiveStep(2, props.step)}>
                        <h2>Register Device</h2>
                    </li>
                    {/* <li className={isActiveStep(3, props.step)}>
                        <h2>Payment</h2>
                    </li> */}
                </ul>
            </Col>
        </>
    )
}



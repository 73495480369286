import { Container, Row, Col, Table, Button, Modal, Form, Card, Image, Dropdown } from 'react-bootstrap';
import { Header } from '../../features/mobile_header/Header';
import * as Icon from 'react-bootstrap-icons';
import { useDispatch, useSelector } from 'react-redux';
import { selectDevice } from '../../features/devices/deviceSlice';
import { deviceListThunk } from '../../features/devices/deviceThunks';
import { useNavigate } from "react-router-dom";
import useDidMountHook from '../../hooks/useDidMountHook';
import { useAuth } from '../../providers/AuthProvider';

const  Devices = () => { 
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [user] = useAuth();
	const { status, event, message, data } = useSelector(selectDevice)

	useDidMountHook(() => {
		dispatch(deviceListThunk({ user_id: user?.id }))
	})

	return (
		<>
			<Header page={'devices'} />
			<Container fluid className="element-page main h-100 pt-5 pb-5">
				<Row className="h-100">
					<Col lg={12}>
						<Row className="content align-items-center">
							<Col lg={10} md={12}><h1>Your Devices</h1></Col>
							<Col lg={2} sm={6} className='pb-1 text-right'>
								<Button className="" variant="primary" onClick={() => navigate('/add-device')}>Add device</Button>
							</Col>
						</Row>

						<Row className="content align-items-stretch">
							 
							{(() => {
								if (status === "loaded") {
									var devices_list = data;

									if (devices_list.length > 0) {
										return (
											<>
												{devices_list.map((device, i) => (
													<Col lg={4} key={i} className='mb-3'>
														<Card className='mx-2' onClick={() => navigate(`/update-device/${device.device_id}`)}>
															<Card.Body>
																<Card.Title>{device.name}</Card.Title>
																<Card.Text className={(device?.paymentStatus === 'Cancelled' ? 'cancel' : (device.isPaidSubscription ? 'active' : 'free')) + ' status hidden'}>{device?.paymentStatus === 'Cancelled' ? 'Cancelled' : (device.isPaidSubscription ? 'Paid' : 'Free')}</Card.Text>
																<Card.Text>{device.device_id}</Card.Text>
																<Card.Text className='mt-5 crypto-logo'>
																	{(device?.cryptoCurrency ?? []).map((crypto, j) => (
																		<span key={j} className='cryptox avatar'>{crypto}</span>
																	))}
																</Card.Text>
															</Card.Body>
														</Card>
													</Col>
												))}
											</>

										)
									} else {
										return (
											<>
												<Col className='col-12 text-center'>No device yet</Col>
											</>
										)
									}
								}
							

								if (status === "loading") {
									return (
										<>
											<Col lg={12}>Loading...</Col>
										</>
									)
								}
							})()}

							<Col lg={4} >
								<Card className='mb-5 mx-2 add-new d-none' style={{
									padding: "3rem 0px"
								}} onClick={() => navigate('/add-device')}>
									<Card.Body>
										<Icon.Plus /> Add Device
									</Card.Body>
								</Card>
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
		</>
	)
}


export default Devices

import { createAsyncThunk } from "@reduxjs/toolkit";
import { currencysymbolList } from "./currencysymbolApi";


export const currencysymbolListThunk = createAsyncThunk(
    'list_symbol',
    async () => {
        return await currencysymbolList();
    }
)

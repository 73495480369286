import { createSlice } from "@reduxjs/toolkit"
import { globalKeys } from "../../utils/tools";
import { currencysymbolListThunk } from "./currencysymbolThunks";

const initialState = {
    status: "initial", // initial, loading, error, loaded
    message: "",
    event: "",
    data: {},
}

export const currencySymbolSlice = createSlice({
    name: "list_symbol",
    initialState,
    reducers: {
        resetCurrencySymbolState: (state) => {
            state.status = "initial"; // initial, loading, error, loade;
            state.message = "";
            state.event = "";
            state.data = {};
        }
    },
    extraReducers: (builder) => {
        // registration
        builder.addCase(currencysymbolListThunk.pending, (state) => {
            state.event = 'list';
            state.status = "loading";
        }).addCase(currencysymbolListThunk.fulfilled, (state, action) => {
            state.event = 'list';
            var response = action.payload;
            state.data = response;
            state.message = 'Success';
            state.status = "loaded";
        }).addCase(currencysymbolListThunk.rejected, (state, action) => {
            state.event = 'list';
            state.status = "error";
            state.message = action.error?.message;
        })
    }
});

export const { resetCurrencySymbolState } = currencySymbolSlice.actions;

export const selectCurrencySymbolSlice = (state) => state.currencysymbol;

export default currencySymbolSlice.reducer;
import React, { useEffect, useReducer, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col, Form, Button, InputGroup } from 'react-bootstrap';
import { useNavigate } from 'react-router'
import { AUTH_EVENTS, selectAuth } from '../../features/auth/authSlice';
import { selectStripe } from '../../features/stripe/stripeSlice';
import { Steps } from '../../features/steps/Steps';
import * as Icon from 'react-bootstrap-icons';
import { toast } from 'react-toastify';
import useLocalStorage from '../../hooks/useLocalStorage';
import serialize from 'form-serialize';
import { deviceRegisterThunk, registrationThunk } from '../../features/auth/authThunks';
import { createCardTokenThunk, subscribeCustomerThunk } from '../../features/stripe/stripeThunks';
import { ACCESS_TOKEN, SESSION_TOKEN } from '../../utils/tools';
import CheckoutForm from '../../components/CheckoutForm';
import {loadStripe} from '@stripe/stripe-js'
import {Elements, useElements, useStripe, CardNumberElement, CardExpiryElement, CardCvcElement} from '@stripe/react-stripe-js';
import { deviceSubscribeThunk } from '../../features/devices/deviceThunks';
import { selectDevice } from '../../features/devices/deviceSlice';

const ON_REGISTER = "on_registration_process";

const AccountNew = () => {;
    const { REACT_APP_PAYMENT_PKEY } = process.env;
    const stripePromise = loadStripe(REACT_APP_PAYMENT_PKEY);
    
    const toastId = useRef();
    const [getToast, setToast] = useState(toastId.current);
    const [registerStorage, setRegisterStorage] = useLocalStorage(ON_REGISTER, JSON.stringify({
        "currentStep": 1,
        "formData": null,
        "deviceData": null
    }));
    const [currentStep, setCurrentStep] = useState(1);
    const [paymentStep, setPaymentStep] = useState(1);
    const [subscritionType, setSubscritionType] = useState('Free');
    const [type, setType] = useState(false)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const form = useRef(null);

    const {
        status: authstatus,
        message: authmessage,
        data: authdata,
        event: authevent
    } = useSelector(selectAuth);

    const handleInputFormData = (e) => {
        const { name, value } = e.target;
        setFormData({
            [name]: value
        })
    }

    const {
        status,
        event,
        message,
        data,
    } = useSelector(selectDevice);

    const [formData, setFormData] = useReducer(
        (prev, next) => {

            return { ...prev, ...next };
        },
        {
            first_name: '',
            last_name: '',
            email: '',
            username: '',
            password: '',
            password_confirmation: '',
            primary_device_id: '454654654',
            contact_no: '',
            type: 'customer'
        }
    )

    const [deviceData, setDeviceData] = useReducer(
        (prev, next) => ({ ...prev, ...next }),
        {
            name: '',
            device_id: '',
            user_id: '', // * authdata?.id ??
            screen: 1,
            cryptoCurrency: ["BTC"],
            cryptoCurrencyId: [1],
            primaryCurrency: "USD",
            brightness: '100',
            mode: 'CryptoX',
        }
    )

    const [cardData, setCardData] = useReducer(
        (prev, next) => ({ ...prev, ...next }),
        {
            number: '',
            cvc: '',
            exp_month: '',
            exp_year: '',
            email: '',
            name: ''
        }
    )

    useEffect(() => {
        // * init checker if there's an existing registration process;
        var registerProcess = JSON.parse(registerStorage)
        if (registerProcess?.formData) {
            // * set's formData to step 1
            setFormData(registerProcess?.formData);
            setCurrentStep(2);
        }

        if (registerProcess?.deviceData) {
            // * set's formData to step 1
            setDeviceData(registerProcess?.deviceData);
            // setCurrentStep(3); //remove subscriptuion payment 13-01
            
            submitToLogin();
        }

        if (registerProcess?.cardData) {
            // * set's formData to step 1
            setCardData(registerProcess?.cardData);
            setCurrentStep(4);
        }
        console.log(registerProcess, "registerStorageregisterStorage");
    }, [])

    useEffect(() => {
        // * listener for auth
        if (authevent === AUTH_EVENTS.register) {
            if (authstatus === "loading") {
                setToast(toast.loading("Please wait", {
                    isLoading: true,
                }))
            }

            if (authstatus === "loaded") {
                toast.update(getToast, {
                    type: "success",
                    isLoading: false,
                    render: authmessage,
                    autoClose: 2000,
                    theme: "colored",
                })
                setRegisterStorage(JSON.stringify(
                    {
                        ...JSON.parse(registerStorage),
                        currentStep: 2,
                        formData: authdata
                    }
                ))
                setFormData(data);
                setCurrentStep(2);
            }

            if (authstatus === "error") {
                toast.update(getToast, {
                    type: "error",
                    isLoading: false,
                    render: authmessage,
                    autoClose: 2000,
                    theme: "colored",
                })
            }
        }

        if (authevent === AUTH_EVENTS.register_device) {
            if (authstatus === "loading") {
                setToast(toast.loading("Please wait", {
                    isLoading: true,
                }))
            }

            if (authstatus === "loaded") {
                toast.update(getToast, {
                    type: "success",
                    isLoading: false,
                    render: authmessage,
                    autoClose: 2000,
                    theme: "colored",
                })
                setRegisterStorage(JSON.stringify(
                    {
                        ...JSON.parse(registerStorage),
                        currentStep: 3,
                        deviceData: authdata
                    }
                ))
                setDeviceData(data);
                // setCurrentStep(3);
                submitToLogin();
            }

            if (authstatus === "error") {
                toast.update(getToast, {
                    type: "error",
                    isLoading: false,
                    render: authmessage,
                    autoClose: 2000,
                    theme: "colored",
                })
            }
        }


    }, [authevent, authstatus])

    useEffect(() => {
        // * listener for stripe payments
        if (event === "device_subsribe") {
            if (status === "loading") {
                setToast(toast.loading("Please wait loading...", {
                    isLoading: true,
                }))
            }
			if (status === "error") {
				toast.error(getToast, {
					type: "error",
					isLoading: false,
					render: message,
					autoClose: 2000,
					theme: "colored",
				})
			}
			if (status === "success") {
                toast.update(getToast, {
                    type: "success",
                    render: 'Successfuly registered device',
                    isLoading: false,
                    autoClose: 2000
                })
                
                submitToLogin();
            }
        }

        // * if paid na update profile?
        // if (status === "loading") {
        //     setToast(toast.loading("Please wait", {
        //         isLoading: true,
        //     }))
        // } else if (status === 'error') {
        //     toast.update(getToast, {
        //         type: "error",
        //         isLoading: false,
        //         render: message,
        //         autoClose: 2000,
        //         theme: "colored",
        //     })
        // } else if (status === 'success-token') {
        //     dispatch(subscribeCustomerThunk(data?.cardToken ?? '', cardData));
        // } else if (status === 'success') {
        //     toast.update(getToast, {
        //         type: "success",
        //         isLoading: false,
        //         render: 'Payment transaction successful',
        //         autoClose: 2000,
        //         theme: "colored",
        //     })

        //     setRegisterStorage(JSON.stringify(
        //         {
        //             ...JSON.parse(registerStorage),
        //             currentStep: 3,
        //             cardData: data
        //         }
        //     ))
        //     // setCurrentStep(4);
        //     submitToLogin();
        // }

    }, [status])

    const submitToLogin = () => {
        let { formData: user, currentStep} = JSON.parse(registerStorage);
        // if (currentStep == 3) {
            window.localStorage.setItem(ACCESS_TOKEN, user?.token);
            window.localStorage.setItem(SESSION_TOKEN, JSON.stringify(user))
            setRegisterStorage(null);
            setTimeout(() => window.location.href = "/", 500)
            return;
        // }
    }

    const submitSubscribe = async (pm) => {
        let { formData: user} = JSON.parse(registerStorage);
        await dispatch(deviceSubscribeThunk({email: user?.email, deviceId: deviceData?.device_id, payment_method: pm?.paymentMethod?.id}));
        submitToLogin();
        // await connectDevice();
    }

    // * Action events
    const submitAccountForm = () => {
        const body = serialize(form.current, { hash: true });
        console.log(formData, "value to submit");
        if (formData?.id) {
            setCurrentStep(2);
            return;
        }

        dispatch(registrationThunk(formData));
    }

    const submitDeviceRegisterForm = () => {
        var { formData } = JSON.parse(registerStorage)
        console.log({
            ...deviceData,
            user_id: formData?.id
        }, "value to submit");
        dispatch(deviceRegisterThunk({
            ...deviceData,
            user_id: formData?.id
        }));
    }
    const connectDevice = () => {
        // navigate('/devices')
        // * call the /api/auth/complete_register
        // toastId.current = toast.loading("Please wait registering device and payment", {
        //     isLoading: true,
        // })
        var { formData } = JSON.parse(registerStorage)

        fetch(`${process.env.REACT_APP_API}/auth/complete_register`, {
            method: "POST",
            body: registerStorage,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': "*",
                'Authorization': `Bearer ${formData?.token}`
            }
        }).then(async res => {
            const body = await res.json();
            // if (res.ok) {
            //     toast.update(toastId.current, {
            //         type: "success",
            //         isLoading: false,
            //         autoClose: 2000,
            //         render: body?.message
            //     })
            //     window.localStorage.setItem(ACCESS_TOKEN, body?.user?.token);
            //     window.localStorage.setItem(SESSION_TOKEN, JSON.stringify(body?.user))
            //     setRegisterStorage(null)
            //     window.location.href = "/";
            //     return;
            // }

            // toast.update(toastId.current, {
            //     type: "error",
            //     autoClose: 2000,
            //     isLoading: false,
            //     render: body?.message
            // })


            return;
        }).catch(err => {
            toast.update(toastId.current, {
                type: "error",
                isLoading: false,
                render: err?.message
            })

            return;
        })

        // * setup for pre auth
        // * navigate to devices
    }
    
    const elementsOptions = {
        currency: 'aud',
        style: {
            base: {
                // lineHeight: '40px',
                fontSize: '16px'
            }
        },
    };

    const validateForm = (e) => {
        e.preventDefault();
        
        serialize(form.current, { hash: true, empty: true });
        if (!formData?.id && formData && formData.password !== formData.password_confirmation) {
            toast.error('Password does not match from password confirmation', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "colored",
            });
            return;
        }
        submitAccountForm();
    }

    const validateField = (field) => {
        if (formData && formData[field] != '') {
            return 'required-validate';
        }
    }

    
    const cancelRegistration = () => {
        if (currentStep >= 1) {
            let { formData: user, currentStep} = JSON.parse(registerStorage);
            window.localStorage.setItem(ACCESS_TOKEN, user?.token);
            window.localStorage.setItem(SESSION_TOKEN, JSON.stringify(user))
            setRegisterStorage(null);
            setTimeout(() => window.location.href = "/", 1000)
            return;
        } else {
            window.location.href = "/";
        }
    }

    return (
        <Container fluid className="element-page register-account h-100">
            <Row className="align-items-center h-100">
                <Steps step={currentStep} />

                {(() => {
                    if (currentStep === 1) {
                        return (
                            <Col lg={8} md={12} className="form-account">
                                <Form ref={form} onSubmit={validateForm}>
                                    <h1>Start by creating an account</h1>

                                    <Row>
                                        <Col lg={6} className={validateField('first_name')}>
                                            <Form.Label>First Name *</Form.Label>
                                            <Form.Control type="text" name="first_name" required value={formData?.first_name} onChange={handleInputFormData} placeholder="John" />
                                        </Col>
                                        <Col lg={6} className={validateField('last_name')}>
                                            <Form.Label>Last Name *</Form.Label>
                                            <Form.Control type="text" name="last_name" required value={formData?.last_name} onChange={handleInputFormData} placeholder="Citizen" />
                                        </Col>
                                        <Col lg={6} className={validateField('contact_no')}>
                                            <Form.Label>Contact No. *</Form.Label>
                                            <Form.Control type="text" name="contact_no" required value={formData?.contact_no} onChange={handleInputFormData} placeholder="+61(xxxxxxxx)" />
                                        </Col>
                                        <Col lg={6} className={validateField('email')}>
                                            <Form.Label>Email *</Form.Label>
                                            <Form.Control type="text" name="email" required value={formData?.email} onChange={handleInputFormData} placeholder="johncitizen@gmail.com" />
                                        </Col>
                                        {!formData?.id && (
                                            <>
                                                <Col lg={6} className={validateField('password')}>
                                                    <Form.Label>Password *</Form.Label>
                                                    <Form.Control type="password" name="password" required value={formData?.password} onChange={handleInputFormData} />
                                                </Col>
                                                <Col lg={6} className={validateField('password_confirmation')}>
                                                    <Form.Label>Confirm Password *</Form.Label>
                                                    <InputGroup>
                                                        <Form.Control name="password_confirmation" required type={(!type ? 'password' : 'text')} value={formData?.password_confirmation} onChange={handleInputFormData} />
                                                        <Icon.EyeFill onClick={() => { setType(!type) }} />
                                                    </InputGroup>
                                                </Col>
                                            </>
                                        )}
                                        <Col lg={12}><br /></Col>
                                    </Row>
                                    <Row className="align-items-center justify-content-center">
                                        <Col lg={6} md={6}>
                                            <Button variant="secondary" onClick={() => { navigate('/login') }}>Cancel</Button>
                                        </Col>
                                        <Col lg={6} md={6} className="text-right">
                                            {(() => {
                                                if (authstatus === "loading") {
                                                    return (
                                                        <Button variant="primary">Loading</Button>
                                                    )
                                                } else {
                                                    return (
                                                        <Button variant="primary" type="submit">Next</Button>
                                                    )
                                                }
                                            })()}
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        )
                    }

                    if (currentStep === 2) {
                        return (
                            <Col lg={8} className="form-account">
                                <h1>Enter your device code to register this device</h1>
                                <br />
                                <Form.Control className={validateField('device_id')} type="text" name="primary_device_id" value={deviceData?.device_id} placeholder="1234-ABCD-5678-EFGH-##" onChange={e => setDeviceData({ ...deviceData, device_id: e.target.value })} />
                                <Form.Label>Device Name</Form.Label>
                                <Form.Control type="text" name="name" value={deviceData?.name} onChange={e => setDeviceData({ name: e.target.value })} />

                                <Row className="align-items-center justify-content-center">
                                    <Col lg={6}>
                                        <Button variant="secondary" onClick={() => cancelRegistration() }>Cancel</Button>
                                    </Col>
                                    <Col lg={6} className="text-right">
                                        <Button variant="secondary" onClick={() => currentStep > 1 ? setCurrentStep(currentStep - 1) : ''}>Previous</Button>
                                        {(() => {
                                            if (status === "loading") {
                                                return (
                                                    <Button variant="primary">Loading</Button>
                                                )
                                            } else {
                                                return (
                                                    <Button variant="primary" onClick={() => submitDeviceRegisterForm()}>Next</Button>
                                                )
                                            }
                                        })()}
                                    </Col>
                                </Row>
                            </Col>
                        )
                    }

                    if (currentStep === 3) {
                        return (
                            <Col lg={8} className="form-account">
                                <h1>Payment</h1>
                                <div className={paymentStep == 1 ? '' : 'hidden'}>
                                    <Row className='align-items-center pricing justify-content-center'>
                                        <Col className='each'>
                                            <label className={(subscritionType == 'Free' ? ' active ' : '')}>
                                                <h2>Free</h2>
                                                <ul>
                                                    <li>Receive data updates every 5 minutes at no cost. <br />&nbsp;</li>
                                                </ul>
                                                <input type="radio" value={'Free'} className="hidden" onChange={() => setSubscritionType('Free') } name="subscriptionType" />
                                            </label>
                                        </Col>
                                        <Col className='each'>
                                            <label className={(subscritionType == 'Monthly' ? ' active ' : '')}>
                                                <h2>$15.00/mo</h2>

                                                <ul>
                                                    <li>Enjoy real-time data refreshes with up to 15-second latency for the most current information.</li>
                                                </ul>
                                                <input type="radio" value={'Monthly'} className="hidden" onChange={() => setSubscritionType('Monthly') } name="subscriptionType" />
                                            </label>
                                        </Col>
                                    </Row>  
                                    <Row className="align-items-center justify-content-center">
                                        <Col lg={6}>
                                            <Button variant="secondary" onClick={() => setCurrentStep(1)}>Cancel</Button>
                                        </Col>
                                        <Col lg={6} className="text-right">
                                            <Button variant="secondary" onClick={() => currentStep > 1 ? setCurrentStep(currentStep - 1) : ''}>Previous</Button>
                                            
                                            {(() => {
                                                    if (subscritionType === 'Free') {
                                                        return (
                                                            <>
                                                                <Button variant="primary" onClick={() => submitToLogin()}>Next</Button>
                                                            </>
                                                        )
                                                    } else {
                                                        return (
                                                            <>
                                                                <Button variant="primary" onClick={() => setPaymentStep(2)}>Next</Button>
                                                            </>
                                                        )
                                                    }
                                            })()}
                                        </Col>
                                    </Row>
                                </div>
                                <div className={paymentStep == 2 ? '' : 'hidden'}>
                                        <Elements stripe={stripePromise}>
                                            <CheckoutForm submitSubscribe={submitSubscribe}/>
                                        </Elements>
                                        <Row className="align-items-center justify-content-center">
                                            <Col lg={6}>
                                                <Button variant="secondary" onClick={() => cancelRegistration() }>Cancel</Button>
                                            </Col>
                                            <Col lg={6} className="text-right">
                                                <Button variant="secondary" onClick={() => paymentStep > 1 ? setPaymentStep(paymentStep - 1) : ''}>Previous</Button>
                                            </Col>
                                        </Row>
                                </div>
                            </Col>
                        )
                    }

                    if (currentStep === 4) {
                        return (
                            <Col lg={8} className="form-account">
                                <h1>Connect Device</h1>
                                <Row>
                                    <Col lg={12}>
                                        <Form.Label>Token</Form.Label>
                                        <Form.Control type="text" placeholder="John" />
                                    </Col>

                                </Row>
                                <Row className="align-items-center justify-content-center">
                                    <Col lg={6}>
                                        <Button variant="secondary" onClick={() => cancelRegistration() }>Cancel</Button>
                                    </Col>
                                    <Col lg={6} className="text-right">
                                        <Button variant="secondary" onClick={() => currentStep > 1 ? setCurrentStep(currentStep - 1) : ''}>Previous</Button>
                                        <Button variant="primary">Next</Button>
                                    </Col>
                                </Row>
                            </Col>
                        )
                    }

                })()}
            </Row>
        </Container>
    )

}

export default AccountNew;
import { customFetch, globalKeys } from "../../utils/tools";

export const currencysymbolList = async () => {
    return await customFetch({
        api_uri: 'fetch_symbols',
        method: "POST"
    });
}


